import { persisted } from 'svelte-local-storage-store';
import type { Writable } from 'svelte/store';

const cartStorageKey = 'cartStorage';
const scooterStorageKey = 'scooterStorage';
const preferencesStorageKey = 'preferencesStorage';

export const preferencesStorage : Writable<IPreferencesStorage> =
	persisted(preferencesStorageKey, {
	currency: 'Kč',
	isFirstVisit: true,
});

export const afterOrderStorage: Writable<IAfterOrderStorage> = persisted('afterOrderStorage', {
	email: ''
});

export const scooterStorage: Writable<IScooterStorage> = persisted(scooterStorageKey, {
	url: '',
	producer: '',
	name: '',
	model: '',
	hasArticles: false,
	hasGraphs: false,
	ccm: 0,
	image: ''
});

export const authStorage: Writable<string> = persisted('authStorage', '');

export const cartStorage: Writable<ICartStorage> = persisted(cartStorageKey, {
	new_articles: { estimated_delivery: '', lines: [], total_price: 0 },
	used_articles: { estimated_delivery: '', lines: [], total_price: 0 },
	total_price: 0,
	checkout: {
		customer: '',
		phone: '',
		email: '',
		street: '',
		street_number: '',
		city: '',
		zip_code: '',
		state: 'CZ',
		company: '',
		company_registration_id: '',
		tax_id: '',
		note: '',
		adress_specification: '',
		pickup_id: '',
		pickup_zip: '',
		pickup_address: '',
		pickup_city: '',
		delivery: {
			id: '',
			info: '',
			price: 0,
			name: ''
		},
		payment: {
			id: '',
			name: '',
			price: 0
		}
	}
});

/**
 * Cart storage interface - must be same as API response in cart service.
 */
export interface ICartLine {
	// product name
	name: string;
	// product url
	url: string;
	// product quantity in cart
	quantity: number;
	// total computed price
	total_price: number;
	// price per unit
	unit_price: number;
	// img url of product
	img: string;
	img_new: string;
	// product availability location (optional)
	availability_location?: string;
	// is Product (new/universal) or used
	used: boolean;
	// @todo: https://neonfox.atlassian.net/browse/SCOOT-752
	// action can be one of the following:
	// R - recommended
	// B - payment in advance
	// C - sale
	// A - discount
	// U - used
	// P - new
	// L
	action: string;
	size: string;
}

export interface IPreferencesStorage {
	currency: string;
	isFirstVisit?: boolean | null | undefined;
}

export interface ICartStorage {
	new_articles: IArticlesUsedAndNew;
	used_articles: IArticlesUsedAndNew;
	total_price: number;
	checkout: ICartStorageCheckout;
	discount_code?: string | null;
	discount_value?: number | null;
	discount_text?: string | null;
}

export interface ICartStorageCheckout {
	customer: string;
	phone: string;
	email: string;
	street: string;
	street_number: string;
	city: string;
	zip_code: string;
	state: string;
	company: string;
	company_registration_id: string;
	tax_id: string;
	street2?: string;
	street_number2?: string;
	city2?: string;
	zip_code2?: string;
	state2?: string;
	note: string;
	adress_specification: string;
	delivery: IDelivery;
	payment: IPayment;
	pickup_id: string;
	pickup_zip: string;
	pickup_address: string;
	pickup_city: string;
}

export interface IArticlesUsedAndNew {
	estimated_delivery: string;
	lines: ICartLine[];
	total_price: number;
}

export interface IDelivery {
	id: string;
	info: string;
	price: number;
	name: string;
}

export interface IScooterStorage {
	url: string;
	producer: string;
	name: string;
	model: string;
	hasArticles: boolean;
	hasGraphs: boolean;
	ccm: number;
	image: string;
}

export interface IScooter {
	producer: string;
	name: string;
	model: string;
	ccm: number;
	url: string;
	main_image: IMainImage;
	has_articles: boolean;
	has_graphs: boolean;
}

export interface IMainImage {
	url_medium: string;
}

export interface IPayment {
	id: string;
	name: string;
	price: number;
}

export interface IAfterOrderStorage {
	email: string;
}